module.exports = {
    // catalogUrl: null;,
    catalogUrl: "https://stac.cloudtostreet.info",
    catalogTitle: "STAC Browser",
    // allowExternalAccess: true, // Must be true if catalogUrl is not given
    allowExternalAccess: false,
    allowedDomains: [],
    detectLocaleFromBrowser: true,
    storeLocale: true,
    locale: "en",
    fallbackLocale: "en",
    supportedLocales: [
        "de",
//      "de-CH",
        "es",
        "en",
        "fr",
//      "fr-CA",
//      "fr-CH",
        "it",
//      "it-CH",
        "ro"
    ],
    apiCatalogPriority: null,
    useTileLayerAsFallback: true,
    displayGeoTiffByDefault: false,
    buildTileUrlTemplate: ({href, asset}) => "https://tiles.rdnt.io/tiles/{z}/{x}/{y}@2x?url=" + encodeURIComponent(asset.href.startsWith("/vsi") ? asset.href : href),
    stacProxyUrl: null,
    pathPrefix: "/",
    historyMode: "history",
    cardViewMode: "cards",
    cardViewSort: "asc",
    showThumbnailsAsAssets: false,
    stacLint: true,
    geoTiffResolution: 128,
    redirectLegacyUrls: false,
    itemsPerPage: 12,
    defaultThumbnailSize: null,
    maxPreviewsOnMap: 50,
    crossOriginMedia: null,
    requestHeaders: {},
    requestQueryParameters: {},
    preprocessSTAC: null,
    // authConfig: {
    //     tokenGenerator: "auth0",
    //     generatorOptions: {
    //       auth0ClientOptions: {
    //         domain: "cloudtostreet.us.auth0.com",
    //         clientId: "gypFmxxUyVAMiwAVys22v52G7qZWw7JS",
    //         audience: "https://cloudtostreet.info/",
    //         cacheLocation: "localstorage",
    //         redirect_uri: "http://localhost:4200/auth-landing/"
    //       },
    //       logoutUrl: "http://localhost:4200"
    //     },
    //     type: "header",
    //     key: "Authorization",
    //     formatter: "Bearer"
    // },
    authConfig: {
      tokenGenerator: "auth0",
      generatorOptions: {
        auth0ClientOptions: {
          domain: "cloudtostreet.us.auth0.com",
          clientId: "gypFmxxUyVAMiwAVys22v52G7qZWw7JS",
          audience: "https://cloudtostreet.info/",
          cacheLocation: "localstorage",
          redirect_uri: "https://stac-browser.floodbase.com/auth"
        },
        logoutUrl: "https://stac-browser.floodbase.com"
      },
      type: "header",
      key: "Authorization",
      formatter: "Bearer"
  }
};
